<template>
  <div class="count-card">
    <ebp-card>
      <b-row align-h="between">
        <b-col cols="8">
          <b-row>
            <b-col cols="12">
              <h6 class="title">{{ title }}</h6>
            </b-col>
            <b-col cols="12">
              <p class="count">
                <number
                  :from="0"
                  :to="count"
                  :duraion="5"
                  :format="format"
                  easing="Power1.easeOut"
                  v-bind="animation"
                />
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="count-icon" cols="4">
          <i :class="['bx', icon]"></i>
        </b-col>
      </b-row>
    </ebp-card>
  </div>
</template>

<script>
export default {
  name: "count-card",
  props: {
    icon: String,
    title: String,
    count: null,
    format: {
      type: Function,
      default(amount) {
        return this.formatNumber(amount);
      }
    },
    animation: Object
  }
};
</script>

<style lang="scss">
.count-card {
  p.title {
    color: grey;
    font-weight: 500;
    font-size: 0.77rem;
  }
  p.count {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
  }
  .count-icon {
    i {
      font-size: 2.5rem;
      border-radius: 100%;
      background: $success;
      color: white;
      padding: 0.75rem;
    }
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
