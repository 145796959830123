var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('daily-sales-graph')],1)],1),_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('count-card',{attrs:{"title":_vm.$t('live-books'),"count":_vm.liveBooks,"icon":"bx-book"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('count-card',{attrs:{"title":_vm.$t('total-clients'),"count":_vm.totalClients,"icon":"bx-user"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('count-card',{attrs:{"title":_vm.$t('total-sales-all-time'),"count":_vm.totalSales,"icon":"bx-purchase-tag"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('count-card',{attrs:{"title":`Total Royalties (£)`,"count":_vm.totalRoyalties,"icon":"bx-money"}})],1)],1),_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","sm":"12","lg":"6"}},[_c('ebp-card',[_c('h6',{staticClass:"title"},[_vm._v("Exceptions")]),_c('p',[_vm._v(" Data in this system has the following exceptions which affect reporting: ")]),_c('ul',{staticClass:"exceptionsList"},[_c('li',{staticClass:"exceptionsList__exception"},[_c('router-link',{attrs:{"to":{
                name: 'AdminBooks',
                query: {
                  missing_isbn: true
                }
              }}},[_c('strong',[_vm._v(_vm._s(_vm.booksMissingIsbns))]),_vm._v(" Books missing ISBN. ")])],1),_c('li',{staticClass:"exceptionsList__exception"},[_c('router-link',{attrs:{"to":{
                name: 'AdminBooks',
                query: {
                  missing_asin: true
                }
              }}},[_c('strong',[_vm._v(_vm._s(_vm.booksMissingAsins))]),_vm._v(" Books missing ASIN. ")])],1),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.booksMissingClients))]),_vm._v(" Books missing clients ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.booksMissingTerritories))]),_vm._v(" Books missing Territories ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.saleRecordsMissingChannels))]),_vm._v(" Sale records not assigned to any Sale Channel ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.outStandingClients))]),_vm._v(" Outstanding clients ")])])])],1),_c('b-col',{attrs:{"cols":"12","sm":"12","lg":"6"}},[_c('ebp-card',[_c('h6',{staticClass:"title"},[_vm._v("Notifications")]),_c('p',[_vm._v(" Here are some key summaries: ")]),_c('ul',{staticClass:"exceptionsList"},[_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.booksSubmittedForPublishing))]),_vm._v(" Books submitted for publishing ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.booksMissingFiles))]),_vm._v(" Books missing files ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.booksNeedingIsbn))]),_vm._v(" Books Needing ISBNs ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.bookUpdateRequests))]),_vm._v(" Book update requests ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.bookUnpublishRequests))]),_vm._v(" Unpublishing requests ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.outstandingPromos))]),_vm._v(" Outstanding promotion requests ")]),_c('li',{staticClass:"exceptionsList__exception"},[_c('strong',[_vm._v(_vm._s(_vm.closedAccountTitles))]),_vm._v(" Titles belonging to inactive accounts ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }