<template>
  <div>
    <b-row>
      <b-col>
        <daily-sales-graph />
      </b-col>
    </b-row>

    <b-row align-h="between">
      <b-col cols="12" sm="6" lg="3">
        <count-card
          :title="$t('live-books')"
          :count="liveBooks"
          icon="bx-book"
        ></count-card>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <count-card
          :title="$t('total-clients')"
          :count="totalClients"
          icon="bx-user"
        ></count-card>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <count-card
          :title="$t('total-sales-all-time')"
          :count="totalSales"
          icon="bx-purchase-tag"
        ></count-card>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <count-card
          :title="`Total Royalties (£)`"
          :count="totalRoyalties"
          icon="bx-money"
        ></count-card>
      </b-col>
    </b-row>
    <b-row align-h="between">
      <b-col cols="12" sm="12" lg="6">
        <ebp-card>
          <h6 class="title">Exceptions</h6>
          <p>
            Data in this system has the following exceptions which affect
            reporting:
          </p>
          <ul class="exceptionsList">
            <li class="exceptionsList__exception">
              <router-link
                :to="{
                  name: 'AdminBooks',
                  query: {
                    missing_isbn: true
                  }
                }"
                ><strong>{{ booksMissingIsbns }}</strong> Books missing ISBN.
              </router-link>
            </li>
            <li class="exceptionsList__exception">
              <router-link
                :to="{
                  name: 'AdminBooks',
                  query: {
                    missing_asin: true
                  }
                }"
                ><strong>{{ booksMissingAsins }}</strong> Books missing ASIN.
              </router-link>
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ booksMissingClients }}</strong> Books missing clients
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ booksMissingTerritories }}</strong> Books missing
              Territories
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ saleRecordsMissingChannels }}</strong> Sale records not
              assigned to any Sale Channel
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ outStandingClients }}</strong> Outstanding clients
            </li>
          </ul>
        </ebp-card>
      </b-col>
      <b-col cols="12" sm="12" lg="6">
        <ebp-card>
          <h6 class="title">Notifications</h6>
          <p>
            Here are some key summaries:
          </p>
          <ul class="exceptionsList">
            <li class="exceptionsList__exception">
              <strong>{{ booksSubmittedForPublishing }}</strong> Books submitted
              for publishing
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ booksMissingFiles }}</strong> Books missing files
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ booksNeedingIsbn }}</strong> Books Needing ISBNs
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ bookUpdateRequests }}</strong> Book update requests
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ bookUnpublishRequests }}</strong> Unpublishing requests
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ outstandingPromos }}</strong> Outstanding promotion
              requests
            </li>
            <li class="exceptionsList__exception">
              <strong>{{ closedAccountTitles }}</strong> Titles belonging to
              inactive accounts
            </li>
          </ul>
        </ebp-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CountCard from "@/components/core/count-card";
import DailySalesGraph from "@/views/client/reports/daily-sales-graph";

export default {
  name: "admin",
  components: {
    CountCard,
    DailySalesGraph
  },
  data() {
    return {
      liveBooks: 0,
      totalClients: 0,
      totalSales: 0,
      totalRoyalties: 0,
      booksMissingIsbns: 0,
      booksMissingAsins: 0,
      booksMissingTerritories: 0,
      saleRecordsMissingChannels: 0,
      outStandingClients: 0,
      booksMissingClients: 0,

      booksSubmittedForPublishing: 0,
      booksMissingFiles: 0,
      booksNeedingIsbn: 0,
      bookUpdateRequests: 0,
      bookUnpublishRequests: 0,
      outstandingPromos: 0,
      closedAccountTitles: 0
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    title() {
      return "Books count";
    }
  },
  mounted() {
    this.getSummaries();
  },
  methods: {
    async getSummaries() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("adminHome/getHomeSummary");

        this.liveBooks = res.data["live-books"];
        this.totalClients = res.data["total-clients"];
        this.totalSales = res.data["total-sales"];
        this.totalRoyalties = res.data["total-royalties"];
        this.booksMissingIsbns = res.data["books-missing-isbn"];
        this.booksMissingAsins = res.data["books-missing-asin"];
        this.saleRecordsMissingChannels = res.data["sales-missing-channels"];
        this.booksMissingTerritories = res.data["books-missing-territories"];
        this.outStandingClients = res.data["outstanding-clients"];
        this.booksMissingClients = res.data["books-missing-clients"];
        this.booksSubmittedForPublishing =
          res.data["books-submitted-for-publishing"];
        this.booksMissingFiles = res.data["books-missing-files"];
        this.booksNeedingIsbn = res.data["books-needing-isbn"];
        this.bookUpdateRequests = res.data["book-update-requests"];
        this.bookUnpublishRequests = res.data["book-unpublish-requests"];
        this.outstandingPromos = res.data["outstanding-promos"];
        this.closedAccountTitles = res.data["closed-account-titles"];
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
h6.title {
  color: #ff9800;
}

.exceptionsList {
  padding: 0;

  &__exception {
    strong {
      display: inline-block;
      width: 100px;
      text-align: right;
      padding-right: 10px;
    }

    list-style: none;
    display: block;
    border-bottom: solid 1px #eee;
    padding: 5px 0;
  }
}
</style>
